import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon } from '../../../assets/svg/iconos';
import { DropdownAll } from '../../../components/forms/DropdownAll';
import { startSaveVenta } from '../../../actions/ventas';
import { getCita, refreshCita } from '../../../actions/citas';
import { DropdownDate } from '../../../components/forms/DropdownDate';
import { DropdownTime } from '../../../components/forms/DropdownTime';
import { setProfesionales } from '../../../actions/profesionales';
import { startUpdateVenta } from '../../../actions/ventas';
import { fetchConToken } from '../../../helpers/fetch';


const METODOS_PAGO = [
    { _id: "Pago Tarjeta App", nombre: "Pago Tarjeta App" },
    { _id: "Pago Tarjeta Sucursal", nombre: "Pago Tarjeta Sucursal" },
    { _id: "Monedero Sucursal", nombre: "Monedero Sucursal" },
    { _id: "Monedero App", nombre: "Monedero App" },
    { _id: "Pago en sucursal App", nombre: "Pago en sucursal App" },
    { _id: "Pago en efectivo", nombre: "Pago en efectivo" },
];

export const ModalVentas = ({ 
    history, 
    citaActive, 
    activeModalVentas, 
    setActiveModalVentas,
    setActiveModalReserva,
    isOpen, 
    onClose,
    setDataModal,
    setModalTwoButtonsOpened,
    setCitaActive
}) => {

	
	
    const dispatch = useDispatch();
    const { uid, empresa } = useSelector(state => state.auth);
    // Redux state
    const { colores } = useSelector(state => state.ui);
    const { servicios } = useSelector(state => state.servicios);
	const { sucursales } = useSelector(state => state.sucursales);
    const { promociones } = useSelector(state => state.promociones);
    const { variantes } = useSelector(state => state.variantes);
    const { profesionales } = useSelector(state => state.profesionales);
	

    // Estados locales para las selecciones
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedVariantes, setSelectedVariantes] = useState([]);
    const [selectedPromociones, setSelectedPromociones] = useState([]);
	const [selectedProfesional, setSelectedProfesional] = useState([]);
	const [selectedSucursal, setSelectedSucursal] = useState([]);
    const [metodoPago, setMetodoPago] = useState([]);
	const [ventaExistente, setVentaExistente] = useState(null);
    const [clienteData, setClienteData] = useState({
        nombre: '',
        apellidos: ''
    });

    const [total, setTotal] = useState(0);

    const handleTotalChange = (e) => {
        const value = e.target.value;
        
        // Permitir solo números y un único punto decimal
        if (/^\d*\.?\d*$/.test(value)) {
            // Si es un punto decimal y ya hay uno, no hacer nada
            if (value.includes('.') && value.split('.').length > 2) return;
            
            // Si hay decimales, limitar a 2 decimales
            if (value.includes('.')) {
                const [integer, decimal] = value.split('.');
                if (decimal && decimal.length > 2) return;
            }
            
            setTotal(value);
        }
    };

	// Añade este useEffect después de los estados
	useEffect(() => {		
		console.log('citaActive: ', citaActive);
		if (citaActive) {
			if (citaActive.venta) {
				// Si existe una venta, cargar datos de la venta
				const fetchVenta = async () => {
					try {
						const resp = await fetchConToken(`ventas/${citaActive.venta}`);
						const data = await resp.json();						
						
						if (data.ok) {
							setVentaExistente(data.venta);
							setTotal(data.venta.importe);
							
							// Cargar datos del cliente
							setClienteData({
								nombre: data.venta.usuario?.nombre || '',
								apellidos: data.venta.usuario?.apellidos || ''
							});
							
							// Cargar datos de la venta
							setSelectedServices(data.venta.servicios || []);
							setSelectedVariantes(data.venta.variantes || []);
							setSelectedPromociones(data.venta.descuentos?.map(d => ({
								_id: d._id,
								nombre: d.descripcion
							})) || []);
							setSelectedProfesional(data.venta.profesional ? [data.venta.profesional] : []);
							setSelectedSucursal([data.venta.sucursal]);
							setMetodoPago([{_id: data.venta.metodoPago, nombre: data.venta.metodoPago}]);
							
							setFechaCalendar(moment(data.venta.fechaCita));
							setTimeCalendar(moment(data.venta.fechaCita));
						}
					} catch (error) {
						console.error('Error al cargar la venta:', error);
					}
				};
				fetchVenta();
			} else {
				setVentaExistente(null);
				// Cargar datos de la cita
				setTotal(citaActive.importe);

				setClienteData({
					nombre: citaActive.usuario?.nombre || '',
					apellidos: citaActive.usuario?.apellidos || ''
				});
				setTotal(citaActive.importe);
				setSelectedServices(citaActive.servicios ? [citaActive.servicios].flat() : []);
				setSelectedVariantes(citaActive.variantes ? [citaActive.variantes].flat() : []);
				setSelectedPromociones(citaActive.promocion ? 
					citaActive.promocion.map(promoId => ({
						_id: promoId,
						nombre: promociones.find(p => p._id === promoId)?.titulo || ''
					})) : []
				);
				setSelectedProfesional(citaActive.profesional ? [citaActive.profesional] : []);
				setSelectedSucursal(citaActive.sucursal ? [citaActive.sucursal] : []);
				setMetodoPago(citaActive.metodoPago ? [{_id: citaActive.metodoPago, nombre: citaActive.metodoPago}] : []);
				setFechaCalendar(moment(citaActive.fecha));
				setTimeCalendar(moment(citaActive.fecha));		
			}
		}
	}, [citaActive, promociones]);

	useEffect(() => {
		recalcularTotal();
	}, [selectedServices, selectedVariantes, selectedPromociones]);

    const recalcularTotal = () => {
        let nuevoTotal = selectedServices.reduce((sum, service) => sum + (service.precio || 0), 0);
		
        selectedVariantes.forEach(variante => nuevoTotal += variante.precio || 0);
		
    	selectedPromociones.forEach(promo => {
			
			const promoSelected = promociones.find(p => p._id === promo._id);
            if (promoSelected.porcentaje) {
                nuevoTotal -= ((nuevoTotal * (promoSelected.porcentaje/100)) / 100);
				
            } else if (promoSelected.cifra) {
                nuevoTotal -= promoSelected.cifra;
            }
        });
		
        setTotal(nuevoTotal/100);
    };

    const handleSubmit = async () => {
        try {
            const ventaData = {
                usuario: citaActive.usuario._id,
                empresa,
                sucursal: selectedSucursal[0]._id,
                profesional: selectedProfesional[0]?._id,
                fechaCita: citaActive.fecha,
                importe: parseFloat(total),
                descuentos: selectedPromociones.map(p => ({
                    _id: p._id,
                    descripcion: p.nombre,
                    importe: promociones.find(promo => promo._id === p._id)?.cifra || '0'
                })),
                servicios: selectedServices,
                variantes: selectedVariantes,
                metodoPago: metodoPago[0]._id,
                cita: citaActive._id
            };

            let response;
            if (ventaExistente) {
                response = await dispatch(startUpdateVenta(ventaExistente._id, ventaData));
            } else {
                response = await dispatch(startSaveVenta(ventaData));
            }

            if (response.ok) {
                // Actualizar la cita con el ID de la venta
				console.log('refreshCita: ', {...citaActive, venta: response.venta._id});

				await dispatch(refreshCita(citaActive._id, {...citaActive, venta: response.venta._id}));                
				//await dispatch(getCita(citaActive._id, empresa));
                                                           
				setVentaExistente(ventaData);
				// Actualizar todos los estados locales con la nueva información
				                                                    
                setDataModal({
                    title: ventaExistente ? 'Pago modificado' : 'Pago registrado',
                    text: [ventaExistente ? 'El pago se ha modificado correctamente' : 'El pago se ha registrado correctamente'],
                    buttonText: 'Aceptar',
                    button2Text: 'Cancelar'
                });
                
                setModalTwoButtonsOpened(true);
                onClose();
            } else {
                setDataModal({
                    title: 'Error',
                    text: ['No se pudo procesar el pago. Por favor, inténtelo de nuevo.'],
                    buttonText: 'Aceptar',
                    button2Text: 'Cancelar'
                });
                setModalTwoButtonsOpened(true);
            }
        } catch (error) {
            console.error('Error al procesar el pago:', error);
            setDataModal({
                title: 'Error',
                text: ['Ha ocurrido un error al procesar el pago'],
                buttonText: 'Aceptar',
                button2Text: 'Cancelar'
            });
            setModalTwoButtonsOpened(true);
        }
    };
	const handleSucursalSelected = sucursal => {       
       
    };
	const handleServicesChanged = (services) => {        
        setSelectedServices(services);		
    }
	const handleProfesionalSelected = profesional => {         
              
    };
	const handlePromocionesSelected = (promosSelected) => {		
        setSelectedPromociones(promosSelected.map(promo => ({
            _id: promo._id,
            nombre: promo.nombre
        })));		
    };

	const handleVariantesChanged = (variantes) => {
        setSelectedVariantes(variantes);    		
    }
	const handleMetodoPagoSelected = (metodoPago) => {
        setMetodoPago(metodoPago);    
    }

    const handleClose = () => {
        onClose();
        setActiveModalReserva(true);
    };

	
	const [fechaCalendar, setFechaCalendar] = useState(citaActive?.fecha ? moment(citaActive.fecha) : moment());
	const [selectDate, setSelectDate] = useState(true);
	const [timeCalendar, setTimeCalendar] = useState(citaActive?.fecha ? moment(citaActive.fecha) : moment());
	const [selectTime, setSelectTime] = useState(true);

    return (
        <>
            <div className={`modal-ventas ${activeModalVentas ? 'active' : ''}`}>
                <div 
                    className="modal-wrapper"
                    style={{ backgroundColor: '#FFFBD6' }}
                >
                    <div className="modal-title">
                        <div
                            className='btn-title'
                            onClick={handleClose}>
                            <h3 style={{ color: '#6366F1' }}>Venta</h3>
                        </div>
                        <div
                            className="btn-close"
                            style={{ backgroundColor: `#FFFBD6` }}
                            onClick={handleClose}
                        >
                            <CloseIcon color="#6366F1" />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className='modal-info-row'>
                            <p className='title'>Cliente:</p>
                            <p className='info'>
                                &nbsp;{clienteData.nombre} {clienteData.apellidos}
                            </p>
                        </div>
                        
                        {/* Servicios */}
                        <DropdownAll
                                items={servicios}
                                texto="Selecciona un servicio"
								itemsSelected = {selectedServices}
                                handleItemsSelected={handleServicesChanged}                                                  
                            />
						{/* Variantes */}
                        <DropdownAll
							items={variantes}                                							
							itemsSelected={selectedVariantes}
							handleItemsSelected={handleVariantesChanged}
						/>

						{/* Sucursales */}
						<DropdownAll                                                                
                                items={sucursales}                                
                                handleItemsSelected={handleSucursalSelected}
                                itemsSelected={selectedSucursal}
								oneSelected={true}
                                
                            />
						
						{/* Profesionales */}
						<DropdownAll							
							items={profesionales}
							handleItemsSelected={handleProfesionalSelected}
							itemsSelected={selectedProfesional}
							oneSelected={true}
						/>
											
						<DropdownDate
							selectDate={selectDate}
							setSelectDate={setSelectDate}
							fechaCalendar={fechaCalendar}
							setFechaCalendar={setFechaCalendar}
							disabled={true}
						/>
						<DropdownTime
							selectTime={selectTime}
							setSelectTime={setSelectTime}
							timeCalendar={timeCalendar}
							setTimeCalendar={setTimeCalendar}
							selectServicio={selectedServices}
							selectSucursal={selectedSucursal}
							profesionales={profesionales}
							setProfesionales={setProfesionales}
							selectProfesional={selectedProfesional}
							setProfSelected={{}}
							fechaCalendar={fechaCalendar}
							setFechaCalendar={setFechaCalendar}
							morning={{}}
							afternoon={{}}
							editCita={true}
							disabled={true}
						/>
                        
                        {/* Método de Pago */}
						<DropdownAll
							items={METODOS_PAGO}
							handleItemsSelected={handleMetodoPagoSelected}
							itemsSelected={metodoPago}
							oneSelected={true}
						/>

						{/* Promociones */}
						<DropdownAll
							items={promociones}
							handleItemsSelected={handlePromocionesSelected}
							itemsSelected={selectedPromociones}
						/>
                        
                        <div className="payment-section">
                            <div className="importe-container">
								<span>IMPORTE</span>
                                <div className='importe-container-inner'>									
									<input
										type="text"
										value={total}
										onChange={handleTotalChange}
									/>
									<span className="euro-symbol">€</span>
								</div>                                
                            </div>
                            <button 
                                className="completar-btn"
                                onClick={handleSubmit}
                            >
                                {ventaExistente ? 'Modificar pago' : 'Completar pago'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};





























                